<template>
    <b-card title="Actions">
      <app-timeline>
  
        <!-- 12 INVOICES HAVE BEEN PAID -->
        <app-timeline-item>
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6> Actions</h6>
            <small class="text-muted">12 min ago</small>
          </div>
          <p> No actions yet</p>
          <p class="mb-0">
             
       
          </p>
        </app-timeline-item>
        
        
      </app-timeline>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BImg, BMedia, BAvatar,
  } from 'bootstrap-vue'
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  
  export default {
    components: {
      BCard,
      BImg,
      BMedia,
      BAvatar,
      AppTimeline,
      AppTimelineItem,
    },
    props: {
      userData: {
        type: Object,
        required: true,
      },
    },
  }
  </script>
  
  <style>
  
  </style>
  