<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        {{resolveUserStatusVariantText( userData.formStatus)}}
      </h5>
      <b-badge :variant="resolveUserStatusVariant(userData.formStatus)">
        {{resolveUserStatusVariantText(userData.formStatus) }}
      </b-badge>
      <small class="text-muted w-100"> {{userData.createdAt | formattedDate }}</small>
    </b-card-header>

    
    <b-card-body class="mt-3" v-if="userData.formStatus==0">
       
       <b-button
         v-ripple.400="'rgba(255, 255, 255, 0.15)'"
         variant="success"
         @click="updateSuggestion(100)"
         block
       >
         Confirm & Close
       </b-button>
       <b-button
         v-ripple.400="'rgba(255, 255, 255, 0.15)'"
         variant="warning"
         @click="updateSuggestion(200)"
         block
       >
         Reject & Close
       </b-button>
       <b-button
         v-ripple.400="'rgba(255, 255, 255, 0.15)'"
         variant="danger"
         v-if="5>6"
         block
       >
        Delete
       </b-button>
     </b-card-body>
     <b-card-body>
       
      
     </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useUsersList from '../participant-list/useParticipantsList'
import moment from 'moment' 
import store from '@/store'
import {   ref } from 'vue'

import { useToast,toRefs } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  filters: {
        formattedDate: function (value) {
          if (value) {
        return moment(String(value)).format('MM/DD/YYYY HH:mm')
    }
        }
    },
  methods: {
  moment: function () {
    return moment();
  },
},
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  setup(props) {
   var toast=useToast();
    const { resolveUserStatusVariantText ,resolveUserStatusVariant} = useUsersList()
    
   
    const updateSuggestion = status => {
    
   var formid=props.userData.id;
 

 

      var data={
        id:formid,
        formStatus:status
      };
    store
      .dispatch('apps-suggestions/updateSuggestion',{data:data} )
      .then(response => {
        props.userData.formStatus=status;
        //console.log(status);
      })
      .catch((e) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text:e
          },
        })
      })
  }
 

    return {
      
      resolveUserStatusVariantText,resolveUserStatusVariant,updateSuggestion
    }
  },
}
</script>

<style>

</style>
